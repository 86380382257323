<template>

<div class="rulesUsers table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">套餐规则用户列表</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="用户名称:">
                <el-input v-model="form.name" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addRecord">添加</el-button>
            </el-form-item>
             <el-form-item>
                <el-button type="primary" :disabled="!multiple" icon="el-icon-remove" class="themed-button" @click="removeRecord">删除</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" @selection-change="handleSelectionChange" stripe border :max-height="maxTableHeight">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column type="index" label="序号" width="70" align="center" :index="indexMethod"/>
            <el-table-column label="用户名称" prop="realname" width=""></el-table-column>
            <el-table-column label="电话" prop="mobile" width="180px"></el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
    <!-- 用户选择表对话框 -->
       <el-dialog title="用户列表" :visible.sync="dialogUserTable" width="60%" append-to-body>
        <el-form :model="userForm" ref="userForm" :inline="true" label-width="60px">
        <el-form-item label="" prop="name" size="mini">
          <el-input v-model="userForm.name" placeholder="用户名" clearable/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="handleQueryItem" size="mini">搜索</el-button>
        </el-form-item>
    </el-form>

      <el-table v-loading="loading" :highlight-current-row="true" :data="userList" @selection-change="handleSelectionItemChange" style="max-height:580px;overflow-y:auto">
        <el-table-column type="selection" width="55" align="center" />
        <!-- <el-table-column type="index" label="序号" width="50" align="center" :index="indexMethod"/> -->
        <el-table-column label="用户ID" align="center" prop="id" show-overflow-tooltip v-if="false"/>
        <el-table-column label="用户名称" align="center" prop="realname" with="220" show-overflow-tooltip />
        <el-table-column label="电话" align="center" prop="mobile" with="180" show-overflow-tooltip></el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination
        @size-change="handleUserSizeChange"
        @current-change="handleUserCurrentChange"
        :current-page="userForm.pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="userForm.pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="userTotal">
        </el-pagination>
    </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="selectItem">确 定</el-button>
        <el-button @click="cancelItem">取 消</el-button>
      </div>
    </el-dialog>
</div>

</template>

<script type="text/ecmascript-6">
import {getRulesUserList,getUserList,saveRulesUser,deleteRulesUser} from '@/api/setMealManagment/mealRules.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import store from '@/store'

import resize from '@/mixins/resize.js'

export default {
    name:'roleMeal',
    mixins:[resize],
    data(){
        return {
            ids:[],
            rulesId:'',
            form:{
                name:'',
            },
            multiple:false,
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogUserTable:false,
            userList:[],
            // 查询参数
            userForm: {
                pageNo: 1,
                pageSize: 30,
                name:undefined,
            },
            loading:false,
            selectedUserIds:[],
            userTotal:0
        } 
    },
    mounted(){
        // let params = this.$route.params;
        // params.rulesId = params.rulesId;
        // this.rulesId = params.rulesId;
        // this._rulesuserList();
    },
    activated(){
        console.log("activated .... ");
    },
    beforeCreate(){
        console.log("beforeCreate .... ");
    },
    created(){
        console.log("create .... ");
        let params = this.$route.params;
        params.rulesId = params.rulesId;
        this.rulesId = params.rulesId;
        this._rulesuserList();
    },
    methods:{
         /** 计算table的行号*/
        indexMethod(index) {
            index = (index + 1) + (this.pageNo - 1) * this.pageSize
            return index
        },
        searchTable(){
            this._rulesuserList();
        },
        addRecord(){
            this.showSelectUserDialog();
        },
        removeRecord(){
            if(!this.ids || this.ids.length == 0){
                this.$alert('请选择一条数据', '提示', {
                    confirmButtonText: '确定',
                });
                return false
            }
            this.$confirm("确认要删除所选的数据吗？", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    userIds:this.ids.join(","),
                    rulesId:this.rulesId
                }
                deleteRulesUser(params).then(res=>{
                    this._rulesuserList();
                    this.multiple = false;
                    this.ids = [];
                    this.$message({showClose: true,message: '删除成功',type: 'success',});
                }).catch(()=>{
                    this.multiple = false;
                    this.ids = [];
                    this.$message({showClose: true,message: '删除失败',type: 'error',});
                });
            }).catch(()=>{

            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._rulesuserList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._rulesuserList()
        },   
        _rulesuserList(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                rulesId:this.rulesId,
            });
            getRulesUserList(params).then((res)=>{
                this.pageNo = res.pageNo;
                this.pageSize = res.pageSize;
                this.total = res.total;
                this.tableData = res.rows;
            }).catch(()=>{

            });
        } ,
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.multiple = selection && selection.length > 0 ;
        },
        showSelectUserDialog(){
            this.dialogUserTable = true
            //初始化套餐选择对话框
            this.userForm = {
                name:'',
                pageSize:15,
                pageNo:1
            }
            this._userList();
        },
        _userList(){
            this.loading = true; 
            getUserList(this.userForm).then((res)=>{
                this.userList = res.rows;
                this.userTotal = res.total;
                this.userForm.pageNo = res.pageNo;
                this.userForm.pageSize = res.pageSize;
                this.loading = false;
            }).catch(()=>{
                this.dialogUserTable = false;
                this.loading = false;
            });
        },
        // 用户多选框
        handleSelectionItemChange(selection) {
            this.selectedUserIds = selection.map(item => item.id);
        },
        handleUserSizeChange(val){
            this.userForm.pageSize  = val
            this._userList();
        },
        handleUserCurrentChange(val){
            this.userForm.pageNo = val
            this._userList();
        },   
        
        handleQueryItem(){
            this.userForm.pageNo = 1;
            this._userList();
        },
        cancelItem(){
            this.dialogUserTable = false;
            this.selectedUserIds = [];
        },
        selectItem(){
            if(!this.selectedUserIds || this.selectedUserIds.length == 0){
                this.$alert('请选择一条数据', '提示', {
                    confirmButtonText: '确定',
                });
                return false
            }
            let params = {
                rulesId:this.rulesId,
                userIds:this.selectedUserIds.join(',')
            };
            saveRulesUser(params).then((res)=>{
                this._rulesuserList();
                this.dialogUserTable = false;
                this.selectedUserIds = [];
                this.$message({showClose: true,message: '保存成功',type: 'success',});
            }).catch(()=>{
                this.dialogUserTable = false;
                this.selectedUserIds = [];
            });
        }
    }
}

</script>
<!--
<style>
.marginR15{margin-right: 15px;}
</style>
-->
